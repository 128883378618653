import styled from "styled-components"
import Contato from "@images/contato.jpg"
export const ContatoWrapper = styled.section`
  width: 100%;
  background-image: url(${Contato});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  padding-bottom: 30px;

  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}
`

export const ContainerContato = styled.div`
  ${({ theme }) => theme.setContainer()};
  ${({ theme }) => theme.media.tablet`
    width: 70%;
  `}

  ${({ theme }) => theme.media.smallPhone`
    padding-left: 10px;
    padding-right: 10px;
    width: 90%;
  `}
`

export const ContentHeader = styled.header`
  max-width: 1000px;
  width: 100%;
  text-align: center;
  text-align: -webkit-center;
  padding-top: 30px;
`

export const ContentContato = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`
