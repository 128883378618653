import React, { useEffect } from "react";
import Aos from "aos";

import Title from "@components/common/Title";
import Description from "@components/common/Description";
import Testimony from "@components/Testimony";
import SliderShow from "@components/common/SliderShow";

import QRCode from "@images/livepix.png"

import {
  LinksWrapper,
  ContainerLinks,
  ContentHeader,
  ContentLinks,
  BorderLinks,
  AmazonWrapper,
} from "./styled"

const Links = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210, once: true, });
  }, []);

  return (
    <LinksWrapper id="Links">
      <ContainerLinks>
        <ContentHeader>
          <Title>
            Quer me ajudar de outras formas?
          </Title>
          <Description>
            Agrade&ccedil;o demais a sua generosidade!<br />
            Se voc&ecirc; curte meu trampo e gostaria de contribuir financeiramente,<br />
            pode faz&ecirc;-lo atrav&eacute;s do LivePix. Acesse meu perfil no link:<br /><br />
            <a href="https://livepix.gg/rockysilvabr" target="_blank">https://livepix.gg/rockysilvabr</a><br /><br />
            Ou atrav&eacute;s do QR Code abaixo:<br /><br />
            <img src={QRCode} border="0" width="300" height="300" /><br /><br /><br />
          </Description>
         </ContentHeader>
      </ContainerLinks>
    </LinksWrapper>
  );
}

export default Links;
