import styled from "styled-components"

const dynanmicVariants = ({ variant, theme }) =>
  ({
    primary: `
    color: ${theme.colors.grayTitle};
  `,

    secondary: `
    color: ${theme.colors.writeHove};
  `,

    light: `
    color: ${theme.colors.yellow};
  `,

    white: `
    color: ${theme.colors.whiteDefault};
  `,
  }[variant])

const Description = styled.h1`
  ${dynanmicVariants};
  font-family: ${({ theme }) => theme.fonts.cloude};
  font-weight: normal;
  font-size: 48px;
  margin: 0;
  line-height: 26px;
  letter-spacing: 2px;
  
  ${({ theme }) => theme.media.tablet`
    font-weight: normal;
    font-size: 40px;
    line-height: 25px;
  `}

  ${({ theme }) => theme.media.phone`
    font-weight: normal;
    width: initial;
    font-size: 30px;
    line-height: 20px;
    padding: 10px;
  `}  
`

export default Description
