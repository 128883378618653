import React, { useEffect, useState } from "react"
import Aos from "aos"
import Title from "@components/common/Title"

import {
  ComandosWrapper,
  ContainerComandos,
  ContentHeader,
  ContentText,
} from "./styled"

const Comandos = () => {
  const [list, setList] = useState([]);
  
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210, once: true })
    fetch("/files/sons.txt")
    .then(response => response.text())
    .then(data => {
      const dataTransform = data.split('\n')
      setList((oldState) => [...oldState, ...dataTransform])
    });
  }, [])

  return (
    <ComandosWrapper id="Comandos">
      <ContainerComandos>
        <ContentHeader data-aos="fade-up">
          <Title variant="primary">LISTA DE COMANDOS DA LIVE</Title>
        </ContentHeader>
        <ContentText data-aos="fade-up">
        <ul>
        {
        list.length && list.map(item => {
          return <li key={item}>{item}</li>
        })
        }
        </ul>
          EM BREVE MAIS… COMING SOON!!<br /><br />
        </ContentText>
      </ContainerComandos>
    </ComandosWrapper>
  )
}

export default Comandos