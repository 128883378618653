import styled from "styled-components"

import img from "@images/back.gif"

export const ComandosWrapper = styled.section`
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  opacity: 0.9;
  padding-bottom: 30px;
  width: 100%;

  ${({ theme }) => theme.media.phone`
    background-position: right;
  `}

  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}
`

export const ContainerComandos = styled.div`
  ${({ theme }) => theme.setContainer()};
`

export const ContentHeader = styled.header`
  font-weight: normal;
  text-align: center;
  text-align: -webkit-center;
  padding-top: 46px;
  width: 100%;
`

export const ContentText = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.whiteDefault};
  font-family: ${({ theme }) => theme.fonts.cloude};
  font-weight: normal;
  font-size: 40px;
  line-height: 20px;
  max-width: 1000px;
  padding: 15px;
  text-align: center;
  width: 100%;

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      width: 25%;
      display: inline-table;
      overflow-wrap: anywhere;
    }
  }

  ${({ theme }) => theme.media.phone`
    max-width: 500px;
    padding: 0px;
    font-size: 30px;
    line-height: 20px;

    & > ul > li {
      width: 33%;
    }
  `}

  ${({ theme }) => theme.media.smallPhone`
    max-width: 360px;
    padding: 0px;
    font-size: 25px;
    line-height: 20px;

    & > ul > li {
      width: 33%;
    }
  `}
`
