import styled from "styled-components"
import img from "@images/back.gif"

export const HeaderWrapper = styled.header`
  width: 100%;
  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}  
  background-image: url(${img});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background: ${({ theme }) => theme.colors.blackFade};
  display: flex;
  padding-bottom: 65px;
  ${({ theme }) => theme.media.tablet`
    padding-top: 0px;
    padding-bottom: 130px;
  `}
  ${({ theme }) => theme.media.phone`
    padding-top: 70px;
    padding-bottom: 130px;
  `}
  ${({ theme }) => theme.media.smallPhone`
    padding-top: 0px;
    padding-bottom: 40px;
  `}
`

export const ContainerHeader = styled.div`
  ${({ theme }) => theme.setContainer()};
`

export const PositionLogo = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 50px 0 0;
`

export const ContentHeader = styled.div`
  margin-top: 20px;
  max-width: 1000px;
  text-align: center;
  width: 100%;
`