import styled from "styled-components"

export const TwitchWrapper = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.blackDefault};
  display: flex;
  padding-bottom: 30px;
  ${({ theme }) => theme.media.phone`
    max-width: 500px;
  `}
  ${({ theme }) => theme.media.smallPhone`
    width: 104%;
  `}
`

export const ContainerTwitch = styled.div`
  ${({ theme }) => theme.setContainer()};
  color: white;
  z-index: 1;
`

export const ContentHeader = styled.header`
  text-align: center;
  text-align: -webkit-center;
  margin-bottom: 30px;
  margin-top: 30px;
  width: 100%;
  max-width: 1000px;
  ${({ theme }) => theme.media.phone`
    max-width: 500px;
  `}
`

export const ContentTwitch = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.tablet`
    grid-template-columns: 1fr;
  `}

  ${({ theme }) => theme.media.phone`
    grid-template-columns: 1fr;
    justify-items: center;
  `}
`

export const SearchCommandsSoundsContainer = styled.div`
  display: flex;
  margin: 25px 0 100px;
  width: 100%;
`

export const ReactSearchAutocompleteContent = styled.div`
  width: 80%;
`

export const SubmitSoundContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30%;
`
export const SubmitSound = styled.button`
  align-items: center;
  background-color: #9147ff;
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  display: inline-flex;
  font-weight: 600;
  font-size: 1.3rem;
  height: 3rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  -webkit-box-align: center;
  -webkit-box-pack: center;

  &:hover {
    background-color: #772ce8;
    cursor: pointer;
    text-decoration: none;
  }
`
