import React, { useEffect, useState } from "react"

import Aos from "aos"
import { TwitchEmbed } from "react-twitch-embed"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { ReactSearchAutocomplete } from "react-search-autocomplete"

import Title from "@components/common/Title"
import Description from "@components/common/Description"

import {
  TwitchWrapper,
  ContainerTwitch,
  ContentHeader,
  ContentTwitch,
  SearchCommandsSoundsContainer,
  ReactSearchAutocompleteContent,
  SubmitSoundContent,
  SubmitSound,
} from "./styled"

const Twitch = () => {
  const [sounds, setSounds] = useState([])
  let soundSelected = ""

  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210, once: true })

    fetch("/files/sons.txt")
      .then(response => response.text())
      .then(data => {
        const dataTransform = data
          .split("\n")
          .map(sound => ({ id: sound, name: sound }))
        setSounds(oldState => [...oldState, ...dataTransform])
      })
  }, [])

  const handleOnSelect = ({ name }) => {
    soundSelected = name
    navigator.clipboard.writeText(soundSelected)
  }

  const handleSubmitSound = () => {
    navigator.clipboard.writeText(soundSelected)
    soundSelected = ""
    toast.success("Comando copiado!")
  }

  return (
    <>
      <TwitchWrapper id="Twitch">
        <ContainerTwitch>
          <ContentHeader>
            <Title variant="primary">TWITCH.TV</Title>
            <Description>
              Lives &agrave;s sextas, s&aacute;bados e domingos.
            </Description>
          </ContentHeader>

          <ContentTwitch>
            <TwitchEmbed
              id="twitch-rockysilvabr"
              channel="rockysilvabr"
              theme="dark"
              width="100%"
              muted
              allowFullscreen={false}
              parent={["web.rockysilva.com.br"]}
            />
          </ContentTwitch>

          <SearchCommandsSoundsContainer>
            <ReactSearchAutocompleteContent>
              <ReactSearchAutocomplete
                items={sounds}
                placeholder="Busque um comando de audio"
                onSelect={handleOnSelect}
                showClear={false}
              />
            </ReactSearchAutocompleteContent>
            <SubmitSoundContent>
              <SubmitSound onClick={handleSubmitSound}>Copiar </SubmitSound>
            </SubmitSoundContent>
          </SearchCommandsSoundsContainer>
        </ContainerTwitch>
      </TwitchWrapper>
      <ToastContainer autoClose={1000} position="bottom-center" />
    </>
  )
}

export default Twitch
