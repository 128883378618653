import React, { useEffect } from "react"
import Aos from "aos"
import Title from "@components/common/Title"
import Description from "@components/common/Description"
import {
  ContatoWrapper,
  ContainerContato,
  ContentHeader,
  ContentContato,
} from "./styled"

const Contato = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210, once: true })
  }, [])

  return (
    <ContatoWrapper id="Contato">
      <ContainerContato>
        <ContentHeader>
          <Title variant="black">
            Contato
          </Title>
          <Description variant="white">Quer trocar uma id&eacute;ia?<br /><a href="https://discord.gg/Nzxv9ErA" target="_blank">Clica aqui e chega junto!</a><br /><br /><br /></Description>
        </ContentHeader>

        <ContentContato>
          <iframe 
            src="https://discord.com/widget?id=374353415389839360&theme=dark" 
            width="100%" 
            height="600" 
            allowtransparency="true" 
            frameBorder="0" 
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            >
          </iframe>
        </ContentContato>
      </ContainerContato>
    </ContatoWrapper>
  )
}

export default Contato