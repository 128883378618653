import styled from "styled-components"

export const FooterWrapper = styled.footer`
  width: 100%;
  display: flex;
`

export const ContainerFooter = styled.div`
  ${({ theme }) => theme.setContainer()};
`

export const ContentFooter = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 30px auto 30px;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grafit};
  display: grid;
`

export const ContentFooterLogo = styled.div`
  margin: 15px auto 15px;
  ${({ theme }) => theme.media.tablet`
    margin: 15px auto 15px;
  `}
  ${({ theme }) => theme.media.phone`
    margin: 5px auto 5px;
  `}
  ${({ theme }) => theme.media.smallPhone`
    margin: 5px auto 5px;
    text-align: center;
  `}
`