import styled from "styled-components"

const dynanmicVariants = ({ variant, theme }) =>
  ({
    primary: `
    color: ${theme.colors.whiteDefault};
    width: 100%;
    font-size: 36px;
  `,

    secondary: `
    color: ${theme.colors.whiteDefault};
    width: 76%;
    font-size: 26px;
    letter-spacing: 2px;
  `,

    light: `
    color: ${theme.colors.bgMenu};
    width: 100%;
    font-size: 26px;
  `,

    black: `
    color: ${theme.colors.whiteDefault};
    width: 100%;
    font-size: 26px;
  `,
  }[variant])

const Title = styled.h2`
  ${dynanmicVariants};
  font-family: ${({ theme }) => theme.fonts.upheaval};
  font-weight: normal;
  margin-top: 0;
  line-height: 46px;

  ${({ theme }) => theme.media.tablet`
    font-weight: normal;
    font-size: 40px;
    line-height: 40px;
  `}

  ${({ theme }) => theme.media.phone`
    font-weight: normal;
    width: initial;
    font-size: 20px;
    line-height: 20px;
  `}
`

export default Title
