import { createGlobalStyle } from 'styled-components';

// font Upheaval
import upheaval from "@fonts/upheaval/upheavtt.ttf"

// font Hachicro Pro
import hachicroPro from "@fonts/hachicro_pro/HachicroPro.ttf"

// font Cloude Regular 1.02
import cloude from "@fonts/cloude/Cloude.otf"


const GlobalStyle = createGlobalStyle`
  html, body, div, head {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  a, a:hover, a:visited, a:link, a:active {
    color: ${({ theme }) => theme.colors.whiteDefault};
    text-decoration: none;
  }

  a:hover {
    color: ${({ theme }) => theme.colors.yellow};
  }

  @font-face {
    font-family: "upheaval";
    src: url(${upheaval});
  }

  @font-face {
    font-family: "hachicroPro";
    src: url(${hachicroPro});
  }  

  @font-face {
    font-family: "cloude";
    src: url(${cloude});
  }  

`

export default GlobalStyle;
