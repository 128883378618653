import React, { useEffect, useState } from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';

import {
  MenuWrapper,
  BtnContent,
  MenuName,
  SideMenu,
  ContainerMenu,
  NavWrapper,
  UnList,
  List,
  Link,
  BoxText,
  TextDescription,
  UlSocial,
  LiSocial,
  IconLink,
  FaceWrapper,
  TwitterWrapper,
  InstaWrapper,
} from "./styled"

const scrollToHeader = Header => () => scrollTo(Header)

const scrollToComandos = ComandosVoz => () => scrollTo(ComandosVoz)

const scrollToTwitch = Twitch => () => scrollTo(Twitch)

const scrollToLinks = Links => () => scrollTo(Links)

const scrollToContato = Contato => () => scrollTo(Contato)

const Menu = () => {
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setScroll(window.scrollY)
      } else {
        setScroll(0)
      }
    })
  }, [scroll])

  return (
    <MenuWrapper>
      <BtnContent htmlFor="bt_menu" scroll={scroll}>
        <MenuName>Menu</MenuName>
        &#9776;
      </BtnContent>

      <input type="checkbox" id="bt_menu" />

      <SideMenu>
        <ContainerMenu>
          <NavWrapper>
            <UnList>
              <List>
                <Link onClick={scrollToHeader("#Header")}>Rocky Silva?</Link>
              </List>
              <List>
                <Link onClick={scrollToTwitch("#Twitch")}>Live na Twitch!</Link>
              </List>
              <List>
                <Link onClick={scrollToComandos("#Comandos")}>Comandos da live</Link>
              </List>
              <List>
                <Link onClick={scrollToLinks("#Links")}>Quer dar uma forcinha?</Link>
              </List>
              {/*
              <List>
                <Link onClick={scrollToYoutube("#Youtube")}>Youtube</Link>
              </List>
              */}
              <List>
                <Link onClick={scrollToContato("#Contato")}>Contato</Link>
              </List>
            </UnList>
          </NavWrapper>

          <BoxText>
            <TextDescription>
              Voc&ecirc; tamb&eacute;m pode me encontrar em v&aacute;rias redes sociais. Me segue l&aacute;, &eacute; s&oacute; clicar a&iacute; em baixo.
            </TextDescription>
          </BoxText>

          <NavWrapper>
            <UlSocial>
              <LiSocial>
                <IconLink href="https://facebook.com/RockySilvaBR" target="_blank">
                  <FaceWrapper />
                </IconLink>
              </LiSocial>

              <LiSocial>
                <IconLink href="https://twitter.com/RockySilvaBR" target="_blank">
                  <TwitterWrapper />
                </IconLink>
              </LiSocial>

              <LiSocial>
                <IconLink href="https://www.instagram.com/rockysilvabr/" target="_blank">
                  <InstaWrapper />
                </IconLink>
              </LiSocial>
            </UlSocial>
          </NavWrapper>
        </ContainerMenu>
      </SideMenu>
    </MenuWrapper>
  )
}

export default Menu;
