import React, { useEffect } from "react";
import Aos from "aos";
import Logo from "@components/Logo/Header";
import Title from "@components/common/Title";
import Description from "@components/common/Description";

import {
  HeaderWrapper,
  ContainerHeader,
  PositionLogo,
  ContentHeader,
} from "./styled"

const Header = () => {
  useEffect(() => {
    Aos.init({ duration: 2000, offset: 210 })
  }, [])

  return (
    <HeaderWrapper id="Header">
      <ContainerHeader>
        <PositionLogo>
          <Logo />
        </PositionLogo>
        <ContentHeader data-aos="fade-up">
          <Title variant="primary">Lindo por&eacute;m Vagabaund's, Semi-Deus e... </Title>
          <Description variant="primary">Seiya do VaiSeiya!, voz do Nuno no game <a href="https://store.steampowered.com/app/1555150/Pocket_Bravery/" target="_blank">Pocket Bravery</a>, voice actor, Gamer (mais retrô) criador de conte&uacute;do, locutor, Humorista... Tenho voz de Seiya, Chapolin e sou meio Chor&atilde;o CBjr. F&atilde; de games da SNK! Mas tamb&eacute;m gosto de outros games Arcade e Consoles!</Description>
        </ContentHeader>
      </ContainerHeader>
    </HeaderWrapper>
  )
}

export default Header;