import styled from "styled-components"

export const LogoWrapper = styled.h1`
  color: ${({ theme }) => theme.colors.whiteDefault};
  font-family: ${({ theme }) => theme.fonts.upheaval};
  width: 114px;
  margin: 0 auto;
  font-size: 28px;
`

export const Highlight = styled.span`
  color: ${({ theme }) => theme.colors.yellow};
`

export const LogoImgWrapper = styled.div`
  width: 170px;
  margin: 0 auto;
`

export const LogoImg = styled.img`
  height: 100%;
  width: 100%;
  margin: 0 auto;
`

export const LogoHeaderImg = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
  margin: 0 auto;
`