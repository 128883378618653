import React from "react"

import { LogoHeaderImg } from "./styled"

import logo from "@images/logo.png"

const LogoHeader = () => (
  <LogoHeaderImg
    src={logo}
    title="Lindo, por&eacute;m Vagabaund's!"
  ></LogoHeaderImg>
)

export default LogoHeader
